<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-map-marker-account
      </v-icon> Site Check-Ins
    </h2>
    <br>
    <v-btn
      small
      color="red darken-3"
      @click="$router.push({ name: 'Locations' })"
    >
      <v-icon left>
        mdi-chevron-double-left
      </v-icon>
      Back
    </v-btn>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="siteCheckIns"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.service_provider.first_name }} {{ item.service_provider.last_name }}</td>
            <td>{{ item.service_provider.company.company_name }}</td>
            <td>
              <span v-if="item.service_provider.contractor_category !== null">
                {{ item.service_provider.contractor_category.name }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>
              <span v-if="item.job_number !== null">
                {{ item.job_number }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.site.name }}</td>
            <td>{{ item.checkin_time | convertToLocal }}</td>
            <td class="my-type-style">
              <v-chip
                outlined
                color="#37474F"
                dark
              >
                {{ item.type }}
              </v-chip>
            </td>
            <td>
              <font
                v-if="item.checkout_time === null"
                class="My-checkout-time"
              >
                Active*
              </font><font v-else>
                {{ item.checkout_time | convertToLocal }}
              </font>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'SiteCheckIns',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    data () {
      return {
        search: '',
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Company', value: 'company_name' },
          { text: 'Category', value: 'category' },
          { text: 'Job Number', value: 'job_number' },
          { text: 'Site', value: 'name' },
          { text: 'Check-In At', value: 'check_in_time' },
          { text: 'Check-In Type', value: 'type' },
          { text: 'Check-Out At', value: 'country' },
        ],
        loading: false,
      };
    },
    computed: {
      siteCheckIns () {
        const list = this.$store.getters['locations/getSiteCheckIns'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
    },
    async mounted () {
      this.loading = true;
      await this.fetchSiteCheckInsList();
      this.loading = false;
    },
    methods: {
      async fetchSiteCheckInsList () {
        const locationId = this.$route.params.locationId;
        await this.$store.dispatch('locations/fetchSiteCheckInsList', {
          location_id: locationId,
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.my-type-style {
  text-transform: capitalize;
}
</style>
